.buttons{
    margin-top:10px;
    margin-left: 60px;
    margin-right: 30px;
    margin-bottom: 10px;
    min-width: 70%;
    z-index: 100;    
    
}

#amazon-button{
    background-color: #FFA41C;
    border: none;
    border-radius:30px;
    cursor:pointer;
    text-align: center;
}

#etsy-button{
    color:white;
    background-color: #222222;
    margin-left: 20px;
    border: none;
    border-radius: 30px;
    cursor:pointer;
    text-align: center;
}
#etsy-link{
    color: white;
}

a{
    padding: 10px;
    font-size: 1rem;
    width: 170px;
    display: block;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .buttons{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

    }

    #amazon-button{
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
    }

    #etsy-button{
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
    }

}


/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
 
    .buttons{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 25px;

    }

    #amazon-button{
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
    }

    #etsy-button{
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
    }

    a{
        padding: 5px;
    }
}

/* Desktops and laptops ----------- */
@media only screen  and (min-width : 1224px) {
    

}