
.contact-body{
    background-color: #f6efe8;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    min-height: 50vh;

}

.contact-body h1{
    margin-left: 60px;
    margin-bottom: 10px;
}

 .contact-methods{

    margin-left: 60px;
    margin-right: 30px;
    min-width: 70%;
    margin-bottom: 0;
    margin-top: 0;
    min-height: 70vh;
    
}

.email-container, .fb-container{
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.insta-container{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}



/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .email-container a, .fb-container a, .insta-container a{
        position: relative;
        bottom: -1px;
        font-size: 0.9rem;
     }
}


/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
 
    .contact-body{
        height: 1250px;
    }

    .contact-methods{
        margin-top: 30px;
    }
    

}
