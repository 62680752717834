.customer-images img{
    width: 550px;
    height: 550px;
    margin: 0 auto;
    margin-bottom: 10px;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .customer-images img {
        height: 300px;
        width: 300px;
        position: relative;
        left: 10px;
    }

}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
 
    .customer-images img {
        height: 400px;
        width: 400px;
        position: relative;
        left: 10px;
    }
    

}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
    

}