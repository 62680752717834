
.mission-container{
    background-color: #f6efe8;
    display: flex;
    flex-direction: column;
}

.mission-container h3{
    margin-bottom: 40px;
}


.mission-statement{
    padding: 10px;
    margin-left: 60px;
    margin-right: 30px;
    min-width: 70%;
    margin-bottom: 30px;
}

.h2-mission{
    margin-top: 35px;
    font-style: italic;
}

.mission{
    font-size: 1.7rem;
    margin-top: 40px;
}

.snippet{
    padding: 10px;
    margin-top: 20px;
    margin-left: 60px;
    margin-right: 30px;
    min-width: 70%;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .mission-container{
        text-align: center;
    }
}


/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
 
    .mission-container{
        text-align: center;
        height: 100vh;
    }    

    .snippet{
        font-size: 1rem;
    }
    
}