
*{
    color: #1D1D1D;
    text-decoration: none;
    box-sizing: border-box;
}

.navbar{
    background-color: white;
    display: flex;
    position: sticky;
    top:0;
    z-index:10;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    width: 100%;
    min-height:80px;
}

.logo{
    height:auto;
    width: 150px;
    margin-left: 60px;
    margin-right: 30px;
}

.header-links{
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    left: 10%;
    min-width: 71.7%;
    position: relative;
}

.header-text{
    margin: 1rem;
    font-size: 1.2rem;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .logo{
        position: absolute;
        left: -15px;
        top:0px;
        width: 150px;
    }

    .navbar{
        height: 90px;
    }

    .header-links{
        margin-left: -12px;
        margin-top: 30px;   
    }

    .header-text{
        position: relative;
        padding: 10px;
        margin: 10px;
        font-size: 1rem;
    }

    #link-1{
        right:5px;
    }

    #link-2{
        position: relative;
        left: 50px;
    }

   

}


/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    
    html,body{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

    
    
}

/* Desktops and laptops ----------- */
@media only screen  and (min-width : 1224px) {
    

}