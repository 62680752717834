
*{
    color: #1D1D1D;
    box-sizing: border-box;
}

.main{
    background-color: #f6efe8;
    display: flex;
    flex-direction: column;
}

.header{
    padding: 10px;
    margin-left: 60px;
    margin-right: 30px;
    font-size: 1.4rem;
    min-width: 70%;
}


.paint-set{
    padding: 10px;
    margin-left: 60px;
    margin-right: 30px;
    min-width: 70%;
}

li{
    list-style: square;
    margin: 10px;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .header{
        font-size: 1.1rem;
        text-align: center;
    }

    .paint-set{
        margin-top: 35px;
    }

    .paint-set h2{
        text-align: center;
    }

}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
 
    .main{
        height: 150vh;
    }
    
    .header{
        font-size: 1.4rem;
        text-align: center;
    }
   
    .paint-set{
        margin-top: 50px;
    }

}

