
.container{
    padding: 10px;
    margin-left: 60px;
    margin-right: 30px;
    min-width: 70%;
}

p{
    padding: 10px;
    width: 90%;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    

    .container{
        margin-top: 35px;
        text-align: center;
    }

    .container h2{
        text-align: center;
    }

}
/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
 
    .container{
        margin-top: 50px;
    }
}
